import './About.css';

import value1 from '../images/about/value1.png';
import value2 from '../images/about/value2.png';
import value3 from '../images/about/value3.png';
import value4 from '../images/about/value4.png';

export default function About() {
    return(
        <div>
            <div id="mission">
                <div id="leftMiss">
                    <h1>Our Mission</h1>
                    <p>Delta Consulting was founded on a passion for education and an
                        understanding that our current educational systems lack the capacity
                        to bridge technical coursework and skills with educational passion.
                    </p>
                    <p>Further, we aim to bring awareness to issues in the education sector
                        and cater our focus on consulting for companies and organizations that
                        have the potential to bridge these gaps we see in education today.
                    </p>
                </div>
                <div id="rightMiss">
                    <svg id="lrMiss" width="288.67525px" height="500px">
                        <polygon points="0,0 0,500 288.67525,0" stroke="transparent" fill="#E7E1DC"/>
                    </svg>
                    <svg id="wideLRM" width="288.67525px" height="500px" viewBox="0 0 288.67525 500">
                        <polygon points="0,0 0,500 288.67525,0" stroke="transparent" fill="#E7E1DC"/>
                    </svg>
                    <svg id="rrMiss" width="291.67525px" height="500px">
                        <defs>
                            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="95%">
                                <stop offset="0%" style={{stopColor:"white", stopOpacity:0.5}} />
                                <stop offset="100%" style={{stopColor:"#E7E1DC", stopOpacity:1}} />
                            </linearGradient>
                        </defs>
                        <polygon points="288.67525,0 291.67525,0 291.67525,500 0,500" stroke="transparent" fill="url(#grad1)"/>
                    </svg>
                    <svg id="wideRRM" width="291.67525px" height="500px" viewBox="0 0 291.67525 500">
                        <defs>
                            <linearGradient id="grad2" x1="0%" y1="0%" x2="0%" y2="95%">
                                <stop offset="0%" style={{stopColor:"white", stopOpacity:0.5}} />
                                <stop offset="100%" style={{stopColor:"#E7E1DC", stopOpacity:1}} />
                            </linearGradient>
                        </defs>
                        <polygon points="288.67525,0 291.67525,0 291.67525,500 0,500" stroke="transparent" fill="url(#grad2)"/>
                    </svg>
                </div>
            </div>
            <div id="values">
                <div id="leftVal">
                    <h1>Core Values</h1>
                    <div className="val">
                        <div className="valIcons"><img src={value1} alt="Rocket Icon"/></div>
                        <h3>Raising the Bar</h3>
                        <p>As an organization, we constantly strive to exceed expectations
                            and expect nothing less than excellent work from our consultants.
                            No goal is too high for us to reach and no standard is left unmet.
                        </p>
                    </div>
                    <div className="val">
                        <div className="valIcons"><img src={value2} alt="Lightbulb Icon"/></div>
                        <h3>Innovative Changemaking</h3>
                        <p>At Delta, we want to be the change we want to see in the world.
                            That means we nurture creativity, embrace new technologies, and
                            take a collaborative and visionary approach to solving problems.
                        </p>
                    </div>
                    <div id="valBotPic">
                        <svg id="lVBP" viewBox="0 0 211.32475 366.0252">
                            <polygon points="0,0 0,366.0252 211.32475,0" stroke="transparent" fill="white"/>
                        </svg>
                        <svg id="rVBP" viewBox="0 0 214.32475 366.0252">
                            <polygon points="214.32475,366.0252 0,366.0252 211.32475,0 214.32475,0" stroke="transparent" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div id="rightVal">
                    <div id="valTopPic">
                        <svg viewBox="0 0 214.32475 366.0252">
                            <polygon points="0,0 0,366.0252 3,366.0252 214.32475,0" stroke="transparent" fill="white"/>
                        </svg>
                    </div>
                    <div className="val">
                        <div className="valIcons" ><img src={value3} alt="Ribbon Icon"/></div>
                        <h3>In This Together</h3>
                        <p>Delta Consulting is an inclusive and driven community that aims to
                            foster lasting professional and personal connections amongst its
                            members. The experiences and times we share make us a family that
                            extends beyond campus limits.
                        </p>
                    </div>
                    <div className="val">
                        <div className="valIcons"><img src={value4} alt="Ribbon Icon"/></div>
                        <h3>Personal and Professional Integrity</h3>
                        <p>Delta consultants are held to the highest degree of accountability
                            and are encouraged to take full ownership of their work. We
                            practice honesty and fairness in all aspects of our work, grow from
                            our mistakes, and celebrate each other’s accomplishments.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
