export default function Event({date, time, title, inviteOnly, text}) {
    var inviteDisplay;
    if (inviteOnly) {
        inviteDisplay = <h3 className="inviteOnly">Invite Only!</h3>;
    } else {
        inviteDisplay = '';
    }
    return(
        <div className="event">
            <h3 className="eventTime">{date} | {time}</h3>
            <h3 className="shortDate">{date}</h3>
            <h3 className="shortTime">{time}</h3>
            {inviteDisplay}
            <svg className="wideTime" viewBox="0 0 400 130" width="400px" height="130px">
                <polygon points="362.4722,65 400,0 400,65" stroke="transparent" fill="#23543B"/>
                <polygon points="0,65 0,130 324.9444,130 362.4722,65" stroke="transparent" fill="#E7E1DC"/>
            </svg>
            <svg className="narrowTime" viewBox="0 0 250 155" width="250px" height="155px">
                <polygon points="212.4722,65 250,0 250,65" stroke="transparent" fill="#23543B"/>
                <polygon points="0,65 0,155 160.5107,155 212.4722,65" stroke="transparent" fill="#E7E1DC"/>
            </svg>
            <div className="eventText">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    );
}
