import './Home.css';
import logo from '../images/home/logo.png';
import quill from '../images/home/quill.png';
import { HashLink as Link } from "react-router-hash-link";
import emailjs from 'emailjs-com';

function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('website_contact_form', 'contact_us_template', e.target, 'user_FDCBwvlbtbeIoMaBjE092')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    document.getElementById('rightCon').reset();
}

export default function Home() {
    return(
        <div>
            <div id="landing">
                <svg id="gradient" viewBox="0 0 500 100">
                    <defs>
                        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="95%">
                            <stop offset="0%" style={{stopColor:"white", stopOpacity:0.5}} />
                            <stop offset="100%" style={{stopColor:"#E7E1DC", stopOpacity:1}} />
                        </linearGradient>
                    </defs>
                    <polygon points="0,0 57.73505,100 500,100 500,0"
                    stroke="transparent" fill="url(#grad1)"/>
                </svg>
                <svg id="lilTri" viewBox="0 0 115.4701 100">
                    <polygon points="0,0 57.73505,100 115.4701,0"
                    stroke="transparent" fill="#E7E1DC" fillOpacity="0.5"/>
                </svg>
                <svg id="bigTri" viewBox="0 0 115.4701 100">
                    <polygon points="0,100 57.73505,0 115.4701,100"
                    stroke="transparent" fill="black"/>
                </svg>
                <img src={logo} alt="Delta Logo"/>
                <div id="titles">
                    <h1>Delta</h1>
                    <h1>Consulting</h1>
                    <h2>For the minds of the future.</h2>
                </div>
                <svg id="appButton" viewBox="0 0 200 50">
                    <Link to="/join">
                        <polygon points="0,0 28.8675,50 200,50 171.1325,0"
                        stroke="transparent" fill="#23543B"/>
                    </Link>
                </svg>
                <Link to="/join" id="actualApp">APPLY NOW</Link>
            </div>
            <div id="blurb">
                <p>Delta Consulting is the student-run, not-for-profit educational
                    consulting organization at UC Berkeley. We provide strategic solutions
                    to companies that we believe have the potential to make a real
                    difference within the education space. Delta members are not only
                    picked through a highly selective process, but also rigorously trained
                    to provide quality recommendations to our clients.
                </p>
                <svg id="normalBlurb" height="344px" width="948.608572px"><polygon points="0,0 0,344 750,344 948.608572,0" stroke="transparent" fill="#E7E1DC"/></svg>
                <svg id="wideBlurb" height="344px" width="948.608572px" viewBox="0 0 948.608572 344"><polygon points="0,0 0,344 750,344 948.608572,0" stroke="transparent" fill="#E7E1DC"/></svg>
            </div>
            <div id="contact">
                <div id="leftCon">
                    <h1>Contact Us</h1>
                    <p>Drop us a line—we'd love to chat!</p>
                    <img src={quill} alt="Delta Quill" id="quill"/>
                </div>
                <form id="rightCon" onSubmit={sendEmail} autoComplete="off">
                    <div id="conFirstRow">
                        <div id="conName"><p>Name*</p><input type="text" name="fullname" required/></div>
                        <div id="conEmail"><p>Email*</p><input type="email" name="email" required/></div>
                        <div id="conPhone"><p>Phone</p><input type="tel" name="phone"/></div>
                    </div>
                    <div id="conMess">
                        <p>Message*</p>
                        <textarea name="message" placeholder="What would you like to discuss?" required></textarea>
                    </div>
                    <label id="conSubmit">
                        <input type="submit" value="SEND FORM"/>
                        <svg viewBox="0 0 180 50">
                            <polygon points="0,0 28.8675,50 180,50 180,0"
                            stroke="transparent" fill="#23543B"/>
                        </svg>
                    </label>
                </form>
            </div>
        </div>
    );
}