import './App.css';
import './pages/Main.css';
import ScrollToTop from './components/ScrollToTop';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import About from './pages/About';
import Work from './pages/Work';
import Members from './pages/Members';
import Join from './pages/Join';
import NotFound from './pages/NotFound'
import Application from './pages/Application';
import Footer from './components/Footer';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <NavBar/>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/work" component={Work} />
        <Route exact path="/members" component={Members} />
        <Route exact path="/join" component={Join} />
        <Route exact path="/app" component={Application} />
        <Route component={NotFound} />
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
