import '../pages/Main.css';
import '../pages/Members.css';

export default function Profile({image, name, suffix, linkedin, title}) {
    var nameFormat;
    if (linkedin) {
        if (suffix) {
            nameFormat = <a href={linkedin}><h3>{name} <span class="suffix">{suffix}</span></h3></a>
        } else {
            nameFormat = <a href={linkedin}><h3>{name}</h3></a>
        }
    } else {
        if (suffix) {
            nameFormat = <h3>{name} <span class="suffix">{suffix}</span></h3>
        } else {
            nameFormat = <h3>{name}</h3>
        }
    }
    return(
        <div className="member">
            <img src={image} alt={name}/>
            <div className="bio">{nameFormat}<p>{title}</p></div>
        </div>
    );
}
