export default function Application() {
    return (
        <iframe 
            src="https://airtable.com/embed/appMJdHbrkOoXPdBp/shr6mzf3yefgHADbb?backgroundColor=purple" 
            title="whatever"
            frameborder="0" 
            width="100%" 
            style={{height: '100vh'}}>
        </iframe>
    );
}
