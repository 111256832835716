import React, { useState, useEffect } from 'react';
import { HashLink as Link } from "react-router-hash-link";
import logoIcon from '../images/general/logo_icon.png';

export default function NavBar() {
    const [showDrop, setShowDrop] = useState(false);
    function handleResize() {
        if (window.innerWidth > 800) {
            setShowDrop(false);
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    return(
        <header>
            <Link to="/"><img src={logoIcon} alt="Delta Logo"></img></Link>
            <ul id="widenav">
                <li className="tabs"><Link to="/">HOME</Link></li>
                <li className="tabs"><Link to="/about">ABOUT US</Link></li>
                <li className="tabs"><Link to="/work">WORK</Link></li>
                <li className="tabs"><Link to="/members">MEMBERS</Link></li>
                <li className="tabs"><Link to="/home#contact">CONTACT</Link></li>
                <li id="join">
                    <svg height="100px" width="160px" viewBox="0 0 160 100">
                        <Link to="/join">
                            <polygon points="0,0 57.73505,100 160,100 160,0"
                            stroke="transparent" fill="#23543B"/>
                        </Link>
                    </svg>
                    <Link to="/join" id="actualjoin">JOIN US</Link>
                </li>
            </ul>
            {!showDrop ?
            <svg id="dropbutton" viewBox="0 0 160 100" width="160px" height="100px">
                <polygon className="clickable" onClick={() => setShowDrop(true)} points="0,0 57.73505,100 160,100 160,0" stroke="transparent" fill="#23543B"/>
                <line className="clickable" onClick={() => setShowDrop(true)} x1="80" y1="35" x2="120" y2="35" stroke="white" strokeWidth="2" strokeLinecap="round" />
                <line className="clickable" onClick={() => setShowDrop(true)} x1="80" y1="50" x2="120" y2="50" stroke="white" strokeWidth="2" strokeLinecap="round" />
                <line className="clickable" onClick={() => setShowDrop(true)} x1="80" y1="65" x2="120" y2="65" stroke="white" strokeWidth="2" strokeLinecap="round" />
            </svg> : 
            <div>
                <svg id="droptrapezoid" viewBox="0 0 100 100" width="244px" height="244px">
                    <polygon points="0,0 57.73505,100 100,100 100,0" stroke="transparent" fill="#23543B"/>
                    <polygon className="clickable" onClick={() => setShowDrop(false)} points="29,12 29,28 45,28 45,12" stroke="transparent" fill="#23543B"/>
                    <line className="clickable" onClick={() => setShowDrop(false)} x1="32" y1="15" x2="42" y2="25" stroke="white" strokeWidth="0.8" strokeLinecap="round" />
                    <line className="clickable" onClick={() => setShowDrop(false)} x1="32" y1="25" x2="42" y2="15" stroke="white" strokeWidth="0.8" strokeLinecap="round" />
                </svg>
                <div id="dropcontent">
                    <Link to="/">Home</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/work">Work</Link>
                    <Link to="/members">Members</Link>
                    <Link to="/home#contact">Contact</Link>
                    <Link to="/join">Join Us</Link>
                </div>
            </div>}
        </header>
    );
}