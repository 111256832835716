import { HashLink as Link } from "react-router-hash-link";

import paw from '../images/general/paw.png';
import instagram from '../images/general/instagram.png';
import facebook from '../images/general/facebook.png';
import linkedin from '../images/general/linkedin.png';
import email from '../images/general/email.png';
import whiteLogo from '../images/general/logo_icon_white.png'

export default function Footer() {
    return(
        <footer>
            <div id="footnav">
                <Link to="/"><img src={whiteLogo} alt="Delta Logo"></img></Link>
                <div id="footlinks">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li className="flines"><p>|</p></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li className="flines"><p>|</p></li>
                        <li><Link to="/work">Work</Link></li>
                        <li className="flines"><p>|</p></li>
                        <li><Link to="/members">Members</Link></li>
                        <li className="flines"><p>|</p></li>
                        <li><Link to="/home#contact">Contact</Link></li>
                        <li className="flines"><p>|</p></li>
                        <li><Link to="/join">Join Us</Link></li>
                    </ul>
                </div>
                <div id="icons">
                    <a href="https://www.instagram.com/deltaberkeley/"><img src={instagram} alt="Instagram Logo" className="socials"/></a>
                    <a href="https://www.facebook.com/Delta-Consulting-at-Berkeley-101602534947482"><img src={facebook} alt="Facebook Logo" className="socials"/></a>
                    <a href="https://www.linkedin.com/company/delta-consulting-berkeley/"><img src={linkedin} alt="Linkedin Logo" className="socials"/></a>
                    <a href="mailto:deltaberkeley@gmail.com"><img src={email} alt="Email Icon" id="email"/></a>
                </div>
            </div>
            <div id="copyright">
                <p>© Delta Consulting at Berkeley 2023 | <a href="https://www.linkedin.com/in/timoteayang/"> Made by <img src={paw} alt="Bear Paw"/></a> | <a href="https://www.ocf.berkeley.edu" id="penguin"><img src="https://www.ocf.berkeley.edu/hosting-logos/ocf-hosted-penguin-dark.svg" alt="Hosted by the OCF" style={{border: 0}}/></a></p>
                <p id="tinytext">We are a student group acting independently of the University of California. We take full responsibility for our organization and this web site.</p>
            </div>
        </footer>
    );
}
